import _ from 'lodash';
import { AnyAction } from 'redux';
import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { BUDGET_OPT_DATA_ENDPOINT } from 'charts/BudgetOptimizationViz/constants';
import { apiEnums, ALGORITHM_TYPE, RESULTS_LIMIT, GOAL_TYPES, CROSS_PLATFORM_ROAS_GOAL_NAME } from 'constantsBase';
import { deleteStrategyCompleted as strategyListDeleteStrategyCompleted, updateClientTestInStrategyList } from 'containers/StrategiesList/actions';
import { mapConfigFromDbToUi } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { CUSTOM_GOAL_FIELDS } from 'containers/StrategyWizard/constants';
import { GoalDB } from 'containers/StrategyWizard/types';
import { mapRevTypeConfigFromDbToUi } from 'containers/StrategyWizard/utils';
import {
  Strategy, ViewFlight, StrategyAnalytics, StrategyFlightRun, Currency,
  AppNexus, APNSegment, Microservices, StrategyRevenueType, Goal,
} from 'utils/copilotAPI';
import desnakify from 'utils/desnakify';
import { StrategyGoalDB } from 'utils/types';
import METRICS from './constants/metricsConstants';
import {
  analyticsDesnakifyFunc,
  getInitialRatePercentageOptionBasedOnStrategyType,
} from './utils/metricsUtils';
import {
  FETCH_STRATEGY, optionsUpdated, FETCH_SEGMENTS_FAILED, FETCH_SEGMENTS,
  FETCH_PIXELS_FAILED, FETCH_PIXELS, DELETE_STRATEGY, DATE_RANGE_CHANGED,
  UPDATE_CLIENT_TEST_FOR_STRATEGY,
} from './constants/actionsConstants';
import {
  setOptions, deleteStrategyCompleted, deleteStrategyFailed, fetchStrategyCompleted,
  fetchSegmentsCompleted, fetchStrategyFailed, fetchPixelsCompleted,
  updateClientTestForStrategyCompleted,
  updateClientTestForStrategyFailed,
} from './actions';
import HELIOS_SAGAS from './components/View/Helios/sagas';
import SEGMENT_RECENCY_SAGAS from './components/View/SegmentRecency/sagas';
import { GET_CYOD_FILE_ENDPOINT } from './components/View/DataUploader/constants';
import { formatAnalayticsByFlight, formatAnalyticsDataDates, isCampaignLevelOptimization } from './utils/tabUtils';
import { AggregationLevel, NUM_DAYS_BACK, STRATEGY_GOAL_ANALYTICS_MICROSERVICE } from './constants/strategyAnalyticsConstants';

const setError = (error) => ({ error: error.message, status: _.get(error, 'response.status', 500) });

// remap config objects for front end use on the strategy object
const buildObjForState = (data, config) => {
  const { strategy, revTypeConfig } = data;
  const strategyTypeId = _.get(strategy, 'strategyType.id');
  const strategyGoals = _.get(strategy, 'strategyGoals');
  const activeGoals = _.filter(strategyGoals, 'active');
  const budget = mapRevTypeConfigFromDbToUi(revTypeConfig);
  const dspId = _.get(strategy, 'strategyType.dsp');
  const newStrategy = {
    ...strategy,
    config: mapConfigFromDbToUi(config, strategyTypeId, dspId, activeGoals),
    strategyGoals: _.sortBy(activeGoals, 'priority'),
    revTypeConfig: budget,
  };
  return { ...data, strategy: newStrategy };
};

const getBudgetOptData = async (strategyId: number) => {
  try {
    const response = await Microservices.runService({ strategy_id: strategyId }, BUDGET_OPT_DATA_ENDPOINT);
    return { response };
  } catch (error) {
    return { error };
  }
};

export function* fetchStrategy({ payload: { id } }: AnyAction) {
  const strategyPopulateCols = [apiEnums.member, apiEnums.advertiser, apiEnums.brand, apiEnums.strategyType, apiEnums.strategyGoals];
  // budget opt data may not be available while strategy goal analytics data is
  // fetch budget opt data separately as its only necessary for monitoring and insights tabs
  const { response, error: err } = yield getBudgetOptData(id);
  const budgetOptData = err ? {} : desnakify(response.data, analyticsDesnakifyFunc);
  try {
    // make universal strategy data calls first
    const apiRes = yield all({
      strategy: call(Strategy.getById, id, { populate: strategyPopulateCols }),
      // @ts-ignore copilot api
      strategyConfig: call(Strategy.configuration, id),
      flights: call(ViewFlight.get, { strategy: id, limit: RESULTS_LIMIT, populate: ['advertiser', 'member'] }),
      latestRuns: call(StrategyFlightRun.getLatest, id),
      status: call(Strategy.getStatus, { id }),
      uploadedFile: call(Microservices.runService, { strategyId: id }, GET_CYOD_FILE_ENDPOINT),
      revTypeConfig: call(StrategyRevenueType.get, { strategy: id, limit: RESULTS_LIMIT, populate: apiEnums.goal }),
    });

    // then make the appropriate strategy analytics calls based on flights and strategy type
    const hasFlightsAttached = _.size(apiRes.flights.data);

    // if flights is empty no point of making this call
    if (!hasFlightsAttached) {
      apiRes.analytics = { data: [] };
      apiRes.analyticsPerFlight = { data: [] };
    }

    const strategy = apiRes.strategy.data;
    // new strategyGoalAnalytics microservice is only available for campaign level optimization strategies
    const useDataFromStrategyGoalAnalytics = isCampaignLevelOptimization(strategy.strategyType.id);
    if (hasFlightsAttached && useDataFromStrategyGoalAnalytics) {
      const analyticsRes = yield all({
        analytics: call(Microservices.runService, { strategy: id }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE),
        analyticsPerFlight: call(Microservices.runService, { strategy: id, aggLevel: AggregationLevel.parent }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE),
        childData: call(Microservices.runService, { strategy: id, aggLevel: AggregationLevel.child }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE),
      });
      const desnakifiedAnalyticsRes = desnakify(analyticsRes, analyticsDesnakifyFunc) as any;
      const {
        analytics: { data: stratData },
        analyticsPerFlight: { data: analyticsPerFlightData },
        childData: { data: childData },
      } = desnakifiedAnalyticsRes;
      const analytics = formatAnalyticsDataDates(_.get(stratData, 'dailyData', []));
      const analyticsPerFlight = formatAnalayticsByFlight(apiRes.flights.data, _.get(analyticsPerFlightData, 'dailyData', []));
      const cumData = {
        analytics: formatAnalyticsDataDates(_.get(stratData, 'cumData', [])),
        analyticsPerFlight: formatAnalayticsByFlight(apiRes.flights.data, _.get(analyticsPerFlightData, 'cumData', [])),
      };

      apiRes.analytics = { data: analytics };
      apiRes.analyticsPerFlight = { data: analyticsPerFlight };
      apiRes.cumData = { data: cumData };
      apiRes.strategyGoalAnalyticsMetadata = { data: _.get(stratData, 'metadata') };
      apiRes.stratData = { data: _.omit(stratData, 'metadata') };
      apiRes.childData = { data: _.omit(childData, 'metadata') };
      apiRes.budgetOptData = { data: budgetOptData };
    }

    // use the previous analytics endpoints if strategy is not a campaign level opt strategy
    if (hasFlightsAttached && !useDataFromStrategyGoalAnalytics) {
      const analyticsRes = yield all({
        analytics: call(StrategyAnalytics.singleStrategy, id, { _days: NUM_DAYS_BACK }),
        analyticsPerFlight: call(StrategyAnalytics.singleStrategyPerFlight, id, { _days: NUM_DAYS_BACK }),
      });
      apiRes.analytics = analyticsRes.analytics;
      apiRes.analyticsPerFlight = analyticsRes.analyticsPerFlight;
    }

    // @ts-ignore copilot api
    let currency;
    // if strat is cross-platform use the currency code within the config in case all flights have been removed
    const crossPlatformCurrencyCode = _.get(apiRes.strategyConfig.data, `[${ALGORITHM_TYPE.crossPlatformOptimization.id}].global_dag_conf.currency_code`);
    if (crossPlatformCurrencyCode) {
      const currencyRes = yield call(Currency.get, { code: crossPlatformCurrencyCode });
      currency = { data: _.head(currencyRes.data) };
    } else {
      const currencyId = hasFlightsAttached
        ? _.get(_.head(apiRes.flights.data), 'currency')
        : apiRes.strategy.data.advertiser.defaultCurrency;
      currency = yield call(Currency.getById, currencyId);
    }
    const data = _.mapValues({ ...apiRes, currency }, (v) => v.data);
    // for crossplatform strategies, conversionRevenue is saved as roas in strategygoal.type
    if (_.some(data.strategy.strategyGoals, { type: CROSS_PLATFORM_ROAS_GOAL_NAME, active: true })) {
      const roasStratGoal = _.find(data.strategy.strategyGoals, { type: CROSS_PLATFORM_ROAS_GOAL_NAME, active: true }) as StrategyGoalDB;
      roasStratGoal.type = GOAL_TYPES.conversionRevenue.value;
    }
    // if awg goal, need to populate strategyGoal.type
    if (_.some(data.strategy.strategyGoals, { type: null, active: true })) {
      const customStrategyGoal = _.find(data.strategy.strategyGoals, { type: null, active: true }) as StrategyGoalDB;
      const { goal: goalId } = customStrategyGoal;
      const { data: goalRes } = yield call(Goal.get, { id: goalId, limit: 1 });
      const customGoalObj = _.head(goalRes) as GoalDB;
      const goalType = _.camelCase(customGoalObj.name);
      customStrategyGoal.type = _.isEqual(GOAL_TYPES.impactOutcome.value, goalType) ? goalType : GOAL_TYPES.awgCreateYourOwn.value;
      customStrategyGoal.customGoal = _.pick(customGoalObj, CUSTOM_GOAL_FIELDS);
    }
    const objForState = buildObjForState(data, apiRes.strategyConfig.data);

    yield put(setOptions(
      optionsUpdated.ratePercentage,
      getInitialRatePercentageOptionBasedOnStrategyType(objForState.strategy, METRICS.ratePercentage.cpc).value,
    ));
    yield put(fetchStrategyCompleted(objForState));
  } catch (error) {
    yield put(fetchStrategyFailed(setError(error)));
  }
}

export function* fetchSegments(action: AnyAction) {
  const { memberId, advertiserId } = action.payload;
  try {
    const params = {
      where: {
        member: memberId,
        extAdvertiserId: advertiserId,
      },
      limit: RESULTS_LIMIT,
      skip: 0,
    };
    const segmentData = yield call(APNSegment.get, params);
    yield put(fetchSegmentsCompleted(segmentData.data));
  } catch (error) {
    yield put({ type: FETCH_SEGMENTS_FAILED, error });
  }
}

function* fetchPixels(action) {
  const { memberId, advertiserId } = action.payload;
  try {
    const pixelData = yield call(AppNexus.pixels, memberId, advertiserId);
    yield put(fetchPixelsCompleted(pixelData.data.response.pixels));
  } catch (error) {
    yield put({ type: FETCH_PIXELS_FAILED, error });
  }
}

export function* deleteStrategy({ payload: { strategy, router } }: AnyAction) {
  const toDelete = strategy[_.keys(strategy)[0]];
  try {
    const deleted = yield call(Strategy.put, toDelete.id, { ..._.pick(toDelete, ['active', 'id']), active: false });
    yield put(deleteStrategyCompleted(deleted.data));
    // triggers detach dag and shows message on list screen
    yield put(strategyListDeleteStrategyCompleted([deleted.data]));
    router.navigate('/');
  } catch (error) {
    yield put(deleteStrategyFailed());
  }
}

export function* updateAnalytics({ payload: { dateRange } }: AnyAction) {
  yield put(setOptions(optionsUpdated.dateRange, { dateRange }));
}

export function* updateTestStrategy({ payload: { strategyId, clientTest, isStrategiesList, showToast } }: AnyAction) {
  try {
    const stratRes = yield call(Strategy.put, strategyId, { clientTest });
    const strategy = stratRes.data;
    // conditionally update the redux state based on the current page (StrategiesList/StrategyAnalytics) where the action is triggered
    yield put(isStrategiesList ? updateClientTestInStrategyList(strategyId, clientTest) : updateClientTestForStrategyCompleted(clientTest));
    showToast({
      type: 'success',
      message: `The following strategy has been successfully ${clientTest ? 'marked' : 'unmarked'} as test: ${strategy.name}`,
      duration: 4000,
      maxMessageLines: 4,
    });
  } catch (error) {
    yield put(updateClientTestForStrategyFailed(error));
    showToast({
      type: 'error',
      message: `There was an error  ${clientTest ? 'marking' : 'unmarking'} strategy ${strategyId} as test`,
      duration: 4000,
      maxMessageLines: 4,
    });
  }
}

export const sagas = [
  takeLatest(DATE_RANGE_CHANGED, updateAnalytics),
  takeLatest(FETCH_STRATEGY, fetchStrategy),
  takeEvery(FETCH_SEGMENTS, fetchSegments),
  takeEvery(FETCH_PIXELS, fetchPixels),
  takeEvery(DELETE_STRATEGY, deleteStrategy),
  takeEvery(UPDATE_CLIENT_TEST_FOR_STRATEGY, updateTestStrategy),
  ...HELIOS_SAGAS,
  ...SEGMENT_RECENCY_SAGAS,
];

export function* strategyAnalyticSagas() {
  yield all([
    ...sagas,
  ]);
}
