/*
 *
 * Validation rules for seats. Currently only checks for empty fields.
 *
 */
import _ from 'lodash';
import { FeeOptions, FeeTypes } from 'constantsBase';
import { required, applyAllValidators, isNumber } from 'utils/formValidators';

const validatePassword = (formValues, fieldName) => {
  const id = _.get(formValues, 'id');

  if (id) { return {}; }
  const value = _.get(formValues, fieldName);

  if (_.isEmpty(value)) {
    return { [fieldName]: 'Required' };
  }

  return {};
};

const validateFeeType = (formValues, fieldName) => {
  const selectedFeeOptionId = _.get(formValues, 'feeOption.id');

  if (selectedFeeOptionId === FeeOptions.fee) {
    const value = _.get(formValues, fieldName);

    if (_.isEmpty(value)) {
      return { [fieldName]: 'Required' };
    }
  }
  return {};
};

const validateFeeValue = (formValues: {}, fieldName: string) => {
  const selectedFeeOptionId = _.get(formValues, 'feeOption.id');
  const feeValueErrors: { feeValue?: unknown } = {};
  const selectedFeeTypeId = _.get(formValues, 'feeType.id');
  const field = _.get(formValues, fieldName);

  if (selectedFeeOptionId === FeeOptions.fee) {
    if (!field) {
      feeValueErrors.feeValue = 'Required';
    }
    if (field) {
      if (field <= 0) {
        feeValueErrors.feeValue = 'Must be greater than 0';
      } else if (selectedFeeTypeId === FeeTypes.percentOfMediaCost && field >= 1) {
        feeValueErrors.feeValue = 'Must be between 0 and 100';
      }
    }
  }
  return feeValueErrors;
};

export default (values) => applyAllValidators(values, {
  externalId: required,
  prefix: [required, isNumber],
  name: required,
  dsp: required,
  country: required,
  businessModel: required,
  businessUnit: required,
  feeOption: required,
  region: required,
  displayName: required,
  apiUser: required,
  enabled: required,
  apiPassword: validatePassword,
  feeType: validateFeeType,
  feeValue: validateFeeValue,
});
