// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segmented-filter-item::part(item) {
  height: 35px;
}

.goalTypeFilter {
  display: flex;
}
.goalTypeFilter .goalTypeDropdown {
  width: 56%;
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/containers/StrategiesList/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;AACF;AACE;EACE,UAAA;EACA,kBAAA;AACJ","sourcesContent":[".segmented-filter-item::part(item) {\n  height: 35px;\n}\n\n.goalTypeFilter {\n  display: flex;\n\n  & .goalTypeDropdown {\n    width: 56%;\n    margin-right: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
