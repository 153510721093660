import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { AgGrid, WppToastContainer } from 'buildingBlocks';
import { User, EnhancedStrategy, Strategy } from 'utils/types';
import HeliosReportModal from 'components/EditStrategyStep/HeliosReportModal';
import { ToastConfig } from 'containers/StrategyAnalytics/types';
import { strategiesTableColumns } from './StrategyTableColumns';

type StrategyTableState = {
  isOpen: boolean
  strategy: EnhancedStrategy
};

type StrategyTableProps = {
  strategyCount: number
  filter: {}
  strategies: Array<EnhancedStrategy>
  heliosDownloadReport: (...args: Array<any>) => void
  downloadingReport: boolean
  downloadedReport: number
  toggleDeleteModal: Function
  user: User
  innerRef: any
  loadingStrategy?: boolean
};

const StrategyTable: React.FC<StrategyTableProps> = (props: StrategyTableProps) => {
  const { toggleDeleteModal, downloadedReport, downloadingReport, innerRef, strategies, strategyCount } = props;
  const [state, setState] = useState<StrategyTableState>({ isOpen: false, strategy: null });
  const toastRef = useRef<HTMLWppToastContainerElement | null>(null);

  const showToast = (config: ToastConfig) => {
    toastRef.current?.addToast(config);
  };

  const handleDeleteStrategy = (strategy: Strategy) => {
    toggleDeleteModal(strategy);
  };

  const onCloseModal = () => {
    setState((prevState: any) => ({ ...prevState, isOpen: false, strategy: null }));
  };

  const handleDownloadStrategy = (data: EnhancedStrategy) => {
    if (data) {
      setState((prevState: any) => ({ ...prevState, isOpen: true, strategy: { ...data, flights: _.values(data.flights) } }));
    }
  };

  const noRowsMessage = `No strategy found. ${_.isEmpty(_.keys(_.omitBy(props.filter, _.isEmpty))) ? '' : ' Please redefine your filters'}`;

  return (
    <>
      {
        state.isOpen && (
          <HeliosReportModal
            {...state.strategy}
            isOpen={state.isOpen}
            downloadedReport={downloadedReport}
            downloadingReport={downloadingReport}
            heliosDownloadReport={props.heliosDownloadReport}
            onCancel={onCloseModal}
          />
        )
      }
      <WppToastContainer maxToastsToDisplay={10} ref={toastRef} />
      <AgGrid
        rowHeight={60}
        ref={innerRef}
        rowSelection="multiple"
        noRowsMessage={noRowsMessage}
        loading={props.loadingStrategy}
        columnDefs={strategiesTableColumns(props.user, handleDeleteStrategy, handleDownloadStrategy, showToast)}
        rowData={_.lt(strategyCount, 1) ? [] : strategies}
        suppressRowClickSelection
      />
    </>
  );
};

export default StrategyTable;
