import React, { ReactNode, useEffect } from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import { Form, Input, Grid, PercentageInput, FieldProps, ObjectMultiDropdown } from 'buildingBlocks';
import ObjectDropdown from 'buildingBlocks/ObjectDropdown';
import { validatedFieldWrapper } from 'utils/wrapperComponents';
import { Region, BusinessUnit, Country, DSPT, FeeOption, BusinessModel, FeeType, Permission, SeatToSaveProps } from 'utils/types';
import { ACCESS_REVENUE_TYPE_PERMISSION, COPILOT_FOR_GROUPM, FeeOptions, FeeTypes } from 'constantsBase';

const ValidatedRenderInput = validatedFieldWrapper(Input);
const ValidatedPctInput = validatedFieldWrapper(PercentageInput);

const PasswordInputField = ({ title, placeholder }: PasswordInputFieldProps) => (
  <Field
    title={title}
    placeholder={placeholder}
    component={ValidatedRenderInput}
    type="password"
    id="apiPassword"
    name="apiPassword"
    autoComplete="new-password"
  />
);

type PasswordInputFieldProps = {
  title: string,
  placeholder: string,
};

type FeeValueProps = {
  component: ReactNode,
  placeholder: string,
};

type FeeValueInputProps = {
  placeholder: string,
  title: string,
  type: string,
};

const FeeValue = ({ component, placeholder, ...passthroughProps }: FeeValueProps) => (
  <Field<FieldProps<FeeValueInputProps>>
    title="Fee Value"
    type="number"
    id="feeValue"
    name="feeValue"
    placeholder={placeholder}
    component={component}
    {...passthroughProps}
  />
);

const getFeeValueProps = (seatToSave: {}) => {
  const selectedFeeTypeId = _.get(seatToSave, 'feeType.id');
  if (selectedFeeTypeId) {
    switch (selectedFeeTypeId) {
      case FeeTypes.cpm:
        return { component: ValidatedRenderInput, labelPosition: 'right', label: 'USD', placeholder: 'Please enter a decimal Fee Value' };
      case FeeTypes.percentOfMediaCost:
        return { component: ValidatedPctInput, labelPosition: 'right', placeholder: 'Please enter a percentage Fee Value' };
      default:
        break;
    }
  }
  return { component: ValidatedRenderInput, placeholder: 'Please enter a Fee Value' };
};

const checkAccessRevenueTypePermission = (seatToSave: SeatToSaveProps) => (_.some(_.get(seatToSave, 'permissions', []), { displayName: ACCESS_REVENUE_TYPE_PERMISSION }));
const getAccessRevenueTypeFromPermissions = (permissions: Array<Permission>) => _.find(permissions, (permission) => _.isEqual(_.get(permission, 'displayName'), ACCESS_REVENUE_TYPE_PERMISSION));

// Apply auto-complete 'new-password' to specify that the input should not try to use something
// that already exist in cache to re-hydrate the field
const SeatFormFields = (props: Props) => {
  const { seatToSave, change, permissions, regions, countries, businessUnits, businessModels, feeOptions, feeTypes, dspList, initialValues: { id } } = props;

  useEffect(() => {
    if (seatToSave && seatToSave.feeOption.id === FeeOptions.noFee) {
      change('feeType', null);
      change('feeValue', null);
    }
    if (seatToSave && seatToSave.externalId) {
      change('externalId', _.trim(seatToSave.externalId));
    }
  }, [change, seatToSave, permissions]);

  const onFeeTypeChange = (v: {}) => {
    change('feeType', v);
    change('feeValue', '');
  };

  const feeValueProps = getFeeValueProps(seatToSave);

  const onBusinessModelChange = (v: {}) => {
    const isCopilotForGroupMSelected = _.isEqual(_.get(v, 'name'), COPILOT_FOR_GROUPM);
    const hasAccessRevenueTypePermission = checkAccessRevenueTypePermission(seatToSave);
    if (seatToSave && isCopilotForGroupMSelected && !hasAccessRevenueTypePermission) {
      const accessRevenueTypePermission = getAccessRevenueTypeFromPermissions(permissions);
      const existingPermissions = _.get(seatToSave, 'permissions', []);
      change('permissions', [accessRevenueTypePermission, ...existingPermissions]);
    }
  };

  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="dsp">DSP</label>
            {/*
              // @ts-ignore - redux form */}
            <Field
              component={ObjectDropdown}
              keyFn={(dsp) => dsp.displayName}
              id="dsp"
              name="dsp"
              options={dspList}
              placeholder="Please choose a DSP"
              fluid
              selection
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Field
              title="External ID"
              component={ValidatedRenderInput}
              id="externalId"
              name="externalId"
              disabled={!!id}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Field
              title="Prefix"
              component={ValidatedRenderInput}
              id="prefix"
              name="prefix"
              disabled={!!id}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Field>
            <Field
              title="Name"
              component={ValidatedRenderInput}
              id="name"
              name="name"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Field
              title="Display Name"
              component={ValidatedRenderInput}
              id="displayName"
              name="displayName"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Field>
            <Field
              title="API User"
              component={ValidatedRenderInput}
              type="search"
              id="apiUser"
              name="apiUser"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            {
              id
                ? <PasswordInputField title="API Password (Optional)" placeholder="▪▪▪▪▪▪" />
                : <PasswordInputField title="API Password" placeholder="Enter the password for this member" />
            }
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="country">Country</label>
            {/*
              // @ts-ignore - redux form */}
            <Field
              component={ObjectDropdown}
              keyFn={(country) => country.name}
              id="country"
              name="country"
              options={countries}
              placeholder="Please choose a Country"
              fluid
              selection
              search={{ searchType: 'local' }}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="businessUnit">Business Unit</label>
            {/*
              // @ts-ignore - redux form */}
            <Field
              component={ObjectDropdown}
              keyFn={(businessUnit) => businessUnit.name}
              id="businessUnit"
              name="businessUnit"
              options={businessUnits}
              placeholder="Please choose a Business Unit"
              fluid
              selection
              search={{ searchType: 'local' }}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="region">Region</label>
            {/*
              // @ts-ignore - redux form */}
            <Field
              component={ObjectDropdown}
              keyFn={(region) => region.name}
              id="region"
              name="region"
              options={regions}
              placeholder="Please choose a Region"
              fluid
              selection
              search={{ searchType: 'local' }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="businessModel">Business Model</label>
            {/*
              // @ts-ignore - redux form */}
            <Field
              component={ObjectDropdown}
              keyFn={(businessModel) => businessModel.name}
              id="businessModel"
              name="businessModel"
              options={businessModels}
              placeholder="Please choose a Business Model"
              fluid
              selection
              search={{ searchType: 'local' }}
              onChange={onBusinessModelChange}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="feeOption">Copilot Fee</label>
            {/*
              // @ts-ignore - redux form */}
            <Field
              component={ObjectDropdown}
              keyFn={(feeOption) => feeOption.name}
              id="feeOption"
              name="feeOption"
              options={feeOptions}
              placeholder="Please choose a Fee Option"
              fluid
              selection
              search={{ searchType: 'local' }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      {(seatToSave && seatToSave.feeOption.id === FeeOptions.fee)
        && (
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <label htmlFor="feeType">Fee Type</label>
                {/*
                // @ts-ignore - redux form */}
                <Field
                  component={ObjectDropdown}
                  keyFn={(feeType) => feeType.name}
                  id="feeType"
                  name="feeType"
                  options={feeTypes}
                  placeholder="Please choose a Fee Type"
                  onChange={onFeeTypeChange}
                  fluid
                  selection
                  search={{ searchType: 'local' }}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                {/*
                // @ts-ignore - redux form */}
                <FeeValue
                  {...feeValueProps}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        )}
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="permissions">Permissions Blacklist</label>
            <Field
              component={ObjectMultiDropdown}
              keyFn={(permission) => permission.displayName}
              id="permissions"
              name="permissions"
              options={permissions}
              placeholder="Excluding permissions"
              fluid
              selection
              search={{ searchType: 'local' }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

type Props = {
  initialValues: {
    id: number
  },
  dspList: Array<DSPT>
  countries: Array<Country>
  businessModels: Array<BusinessModel>
  businessUnits: Array<BusinessUnit>
  feeOptions: Array<FeeOption>
  feeTypes: Array<FeeType>
  regions: Array<Region>
  seatToSave: SeatToSaveProps
  change: Function // redux form change
  permissions: Array<Permission>
};

export default SeatFormFields;
