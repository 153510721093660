import _ from 'lodash';
import React from 'react';
import { ObjectMultiDropdown } from 'buildingBlocks';
import { ObjectDropdownSearch } from 'buildingBlocks/ObjectDropdown/ObjectDropdown';
import { Advertiser, AdvertiserWithPopulatedMember, GoalType, Member, PrettyOption, StrategyType } from 'utils/types';

export type Filter = {
  member: Array<number> | Array<Member>
  advertiser: Array<number> | Array<Advertiser>
  strategyType: Array<number> | Array<StrategyType>
  createdBy: string
  updatedBy: string
  sort: string
  order: string
  starred: boolean
};

type FilterDropdownProps = {
  filterType: { ID: string, NAME: string, SELECTED?: string }
  onChange: (options: any) => void
  options: Array<AdvertiserWithPopulatedMember | PrettyOption | GoalType>
  value: Array<AdvertiserWithPopulatedMember | PrettyOption | GoalType>
  keyFn: (option: any) => void
  loading?: boolean
  error?: string
  renderLabel?: Function
  search?: ObjectDropdownSearch
};

export const FilterDropdown = (props: FilterDropdownProps) => (
  <ObjectMultiDropdown
    {..._.omit(props, 'filterType')}
    fluid
    selection
    scrolling
    name={props.filterType.NAME}
    placeholder={`${props.filterType.NAME}`}
    disabled={props.loading || !!props.error}
    // @ts-ignore id & noResultsMessage don't exist within ObjectMultiDropdown props but do for the semantic dropdown it is built from - jChiu
    id={props.filterType.ID}
    noResultsMessage={props.options.length > 0 ? 'No remaining options.' : `No ${props.filterType.NAME} available.`}
  />
);

type PrettyAdvertiserNameProps = {
  advertiser: AdvertiserWithPopulatedMember
};

export const PrettyAdvertiserName = ({ advertiser }: PrettyAdvertiserNameProps) => (
  <span>
    <span style={{ color: 'grey' }}>{advertiser.member.displayName}</span>
    <span style={{ marginLeft: '.5em' }}>|</span>
    <span style={{ marginLeft: '.5em' }}>
      {advertiser.name} ({advertiser.externalId})
    </span>
  </span>
);
