// @ts-nocheck issues with metric return types
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { SetStateAction } from 'react';
import { Dispatch } from 'redux';
import { STRATEGY_TYPE, GOAL_TYPES, GOAL_VALUE_TYPE, MetricsFormattingConstants, CROSS_PLATFORM_ROAS_GOAL_NAME } from 'constantsBase';
import { toCamelCase } from 'utils/formattingUtils';
import { mapAndPick, generateDayRange, accumulate } from 'utils/functionHelpers';
import { StrategyType, Strategy } from 'utils/types';
import { getPrimaryGoalType } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { isCYODGoalTypeValue } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { getSingleRevTypeAndValue, isNCSGoal } from 'containers/StrategyWizard/utils';
import { setOptions } from '../actions';
import { optionsUpdated } from '../constants/actionsConstants';
import { chartSeriesConfigs } from '../constants/chartSeriesConfigs';
import METRICS, { SelectedMetric, Metric, SYSTEM_GOALS_WITHOUT_ANALYTICS_KEYS } from '../constants/metricsConstants';
import { LEGACY_PREFIX } from '../constants/strategyAnalyticsConstants';
import { AnalyticsData, HighChartsConfig, LeafAnalytics, StrategyGoalAnalyticsDatum, StrategyGoalAnalyticsGoalType, StrategyGoalAnalyticsMetadataType } from '../types';

// only camelCase if the key contains `_`;
export const analyticsDesnakifyFunc = (key: string) => (_.includes(key, '_') ? _.camelCase(key) : key);

export const AGGREGATABLE_COLS = [
  ..._.map(_.values(METRICS.aggregator), 'value'),
  'advCost',
  'advRevenue',
  'viewMeasuredImps',
  'viewDefViewedImpsGroupM',
  'viewMeasuredImpsGroupM',
];

export const globalDefaultMetrics = (metrics: typeof METRICS = METRICS) => ({
  impressions: metrics.aggregator.impressions,
  ctr: metrics.ratePercentage.ctr,
  netCpc: metrics.ratePercentage.netCpc,
  netCpm: metrics.ratePercentage.netCpm,
});

export const strategyDefaultMetrics = (metrics: typeof METRICS, strategyTypeId: string) => {
  const defaults = {
    [STRATEGY_TYPE.heliosSegmentRecency.id]: {
      impressions: metrics.aggregator.impressions,
      ctr: metrics.ratePercentage.ctr,
      netCpc: metrics.ratePercentage.netCpc,
      netCpa: metrics.ratePercentage.netCpa,
      netCpm: metrics.ratePercentage.netCpm,
      margin: metrics.ratePercentage.margin,
    },
  };
  return _.get(defaults, strategyTypeId);
};

const ALGO_METRICS_PER_GOAL = {
  [GOAL_TYPES.cpa.value]: {
    impressions: METRICS.aggregator.impressions,
    conversionRate: METRICS.ratePercentage.conversionRate,
    netCpa: METRICS.ratePercentage.netCpa,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
  [GOAL_TYPES.cpc.value]: {
    impressions: METRICS.aggregator.impressions,
    ctr: METRICS.ratePercentage.ctr,
    netCpc: METRICS.ratePercentage.netCpc,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
  [GOAL_TYPES.cpcv.value]: {
    impressions: METRICS.aggregator.impressions,
    completionRate: METRICS.ratePercentage.completionRate,
    netCpcv: METRICS.ratePercentage.netCpcv,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
  [GOAL_TYPES.conversionRate.value]: {
    impressions: METRICS.aggregator.impressions,
    conversionRate: METRICS.ratePercentage.conversionRate,
    netCpa: METRICS.ratePercentage.netCpa,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
  [GOAL_TYPES.ctr.value]: {
    impressions: METRICS.aggregator.impressions,
    ctr: METRICS.ratePercentage.ctr,
    netCpc: METRICS.ratePercentage.netCpc,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
  [GOAL_TYPES.ivrMeasured.value]: {
    impressions: METRICS.aggregator.impressions,
    ivrMeasured: METRICS.ratePercentage.ivrMeasured,
    netVcpm: METRICS.ratePercentage.vcpm,
    ctr: METRICS.ratePercentage.ctr,
    margin: METRICS.ratePercentage.margin,
  },
  [GOAL_TYPES.completionRate.value]: {
    impressions: METRICS.aggregator.impressions,
    completionRate: METRICS.ratePercentage.completionRate,
    netCpcv: METRICS.ratePercentage.netCpcv,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
};

// Note: these will use 'netCpcv' and 'netCpc' once some backend work is completed to support it
const BUDGET_OPT_METRICS_PER_REVENUE_TYPE = {
  [GOAL_TYPES.cpcv.value]: {
    impressions: METRICS.aggregator.impressions,
    ctr: METRICS.ratePercentage.ctr,
    cpcv: METRICS.ratePercentage.cpcv,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
  [GOAL_TYPES.cpc.value]: {
    impressions: METRICS.aggregator.impressions,
    ctr: METRICS.ratePercentage.ctr,
    cpc: METRICS.ratePercentage.cpc,
    netCpm: METRICS.ratePercentage.netCpm,
    margin: METRICS.ratePercentage.margin,
  },
};

const GOAL_METRICS = {
  [STRATEGY_TYPE.heliosSegmentRecency.id]: ALGO_METRICS_PER_GOAL,
  [STRATEGY_TYPE.helios.id]: ALGO_METRICS_PER_GOAL,
};

const REVENUE_TYPE_METRICS = {
  [STRATEGY_TYPE.apnBudgetOptimization.id]: BUDGET_OPT_METRICS_PER_REVENUE_TYPE,
  [STRATEGY_TYPE.ttdBudgetOptimization.id]: BUDGET_OPT_METRICS_PER_REVENUE_TYPE,
  [STRATEGY_TYPE.dbmBudgetOptimization.id]: BUDGET_OPT_METRICS_PER_REVENUE_TYPE,
};

const goalDefaultMetrics = (strategyTypeId, goalType) => _.chain(GOAL_METRICS)
  .get(strategyTypeId)
  .get(goalType)
  .value();

const revenueTypeDefaultMetrics = (strategyTypeId, goalType) => _.chain(REVENUE_TYPE_METRICS)
  .get(strategyTypeId)
  .get(goalType)
  .value();

const optionValues = ['text', 'value', 'strategyAnalyticsDropdownOverrideText'];
export const getMetricOptions = (metricType: string, metricsToUse: typeof METRICS = METRICS) => _.chain(metricsToUse)
  .get(metricType, [])
  // advRevenue was added to aggregator metrics for dataviz purposes
  // same with eventBudgetDelivered
  .omit([METRICS.ratePercentage.upLift.value, METRICS.ratePercentage.eventBudgetDelivered.value, METRICS.ratePercentage.currentPacingPaused.value, METRICS.aggregator.advRevenue.value,
    METRICS.ratePercentage.finalPacing.value, METRICS.ratePercentage.costPerImpValue.value, METRICS.ratePercentage.impValuePerImp.value])
  .thru((metrics) => mapAndPick(metrics, optionValues))
  .map<any>((metric) => ({ ..._.omit(metric, 'strategyAnalyticsDropdownOverrideText'), text: metric.strategyAnalyticsDropdownOverrideText ?? metric.text }))
  .value();

export const shouldUseCustomGoalKey = (goal: StrategyGoalAnalyticsGoalType) => (!goal.isSystemGoal
  || _.includes(SYSTEM_GOALS_WITHOUT_ANALYTICS_KEYS, _.camelCase(goal.name))
  || _.startsWith(goal.name, LEGACY_PREFIX))
  || isCYODGoalTypeValue(_.camelCase(goal.name))
  || isNCSGoal(goal.name);

export const includeAdditionalMetrics = (goal: StrategyGoalAnalyticsGoalType, data: StrategyGoalAnalyticsDatum, dispatch?: Dispatch<SetStateAction<any>>) => {
  const updatedRatePercentage = _.reduce(
    METRICS.ratePercentage,
    (result: any, metricDetails: Metric, metric: string) => {
      // for cross-platform strategies the key from the data will be 'roas' which is always included in StrategyGoalAnalyticsDatum
      const metricToAggregateBy = _.isEqual(metric, GOAL_TYPES.conversionRevenue.value) ? CROSS_PLATFORM_ROAS_GOAL_NAME : metric;

      // eslint-disable-next-line no-param-reassign
      result[metric] = {
        ...metricDetails,
        func: (arr: LeafAnalytics) => _.sumBy(arr, metricToAggregateBy),
      };
      return result;
    },
    {},
  );

  // return base metrics with updated functions if shouldUseCustomGoalKey conditions are not met
  if (!shouldUseCustomGoalKey(goal)) {
    return {
      ratePercentage: updatedRatePercentage,
      aggregator: METRICS.aggregator,
    };
  }

  const goalMetricKey = _
    .chain(data)
    .keys()
    .find((metric: string) => _.startsWith(metric, 'goal1'))
    .value();
  // remove the goal1 prefix or goal1Legacy prefix if it is a legacy system goal
  const goalName = _.replace(
    goalMetricKey,
    goal.isSystemGoal && _.includes(goalMetricKey, 'Legacy') ? 'goal1Legacy' : 'goal1',
    '',
  );
  const goalValue = _.camelCase(goalName);

  const goalDisplayName = goal.displayName;
  const placeholderGoal = _.camelCase(goalDisplayName);
  // condititonal configurations are necessary for legacy conversion revenue goal - single platform strat with ROAS goal type
  const isLegacyConversionRevGoal = goal.isSystemGoal && _.isEqual(goalMetricKey, 'goal1LegacyConversionRevenue');

  // add info for custom goal types, legacy specific goal types, or unaccounted for system goal types to rate percentage metrics
  const updatedRatePercentageWithGoalMetric = {
    // if data is available the goalMetricKey would exist
    ...(goalMetricKey && {
      [goalValue]: {
        text: goalDisplayName,
        value: goalValue,
        strategyAnalyticsDropdownOverrideText: goalDisplayName,
        additionalFormatting: GOAL_VALUE_TYPE.NONE,
        format: MetricsFormattingConstants.THREE_DECIMALS,
        ...(isLegacyConversionRevGoal && updatedRatePercentage.conversionRevenue), // include base metric values for legacy conversion revenue goal
        func: (arr: LeafAnalytics) => _.sumBy(arr, goalMetricKey),
      },
    }),
    // selection placeholder for the user's goal when there is no data and goal is not legacyConversionRevenue (placeholder already exists in base metrics)
    ...((!data && !isLegacyConversionRevGoal) && {
      [placeholderGoal]: {
        text: goalDisplayName,
        value: placeholderGoal,
        strategyAnalyticsDropdownOverrideText: goalDisplayName,
        additionalFormatting: GOAL_VALUE_TYPE.NONE,
        format: MetricsFormattingConstants.THREE_DECIMALS,
        func: (arr: LeafAnalytics) => _.sumBy(arr, placeholderGoal),
      },
    }),
    // conditionally omit conversionRevenue key if the goal is legacyConversionRevenue
    ..._.omit(updatedRatePercentage, isLegacyConversionRevGoal ? GOAL_TYPES.conversionRevenue.value : []),
  };

  // if dispatch is provided set the default rate percentage option to the goal created by the user
  if (dispatch) {
    dispatch(setOptions(optionsUpdated.ratePercentage, _.isEmpty(goalValue) ? placeholderGoal : goalValue));
  }

  // add any weighted metrics to the aggregator metrics
  const updatedAggregator = _.reduce(goal.weightedMetrics, (result, metric: string) => {
    const metricKey = _.camelCase(`weighted ${metric}`);
    const metricText = _.startCase(metricKey);
    // eslint-disable-next-line no-param-reassign
    result[metricKey] = {
      value: metricKey,
      text: metricText,
      strategyAnalyticsDropdownOverrideText: metricText,
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.THREE_DECIMALS,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metricKey),
    };
    return result;
  }, { ...METRICS.aggregator });

  return {
    ratePercentage: updatedRatePercentageWithGoalMetric,
    aggregator: updatedAggregator,
  };
};

/**
 * Returns a list of metrics to display both in the flight table for a
 * strategy.
 * @param {Object} strategy The strategy for which we are fetching a set of
 * @param {Object} analytics analytics data from strategygoalanalytics endpoint
 * metrics.
 * @return {Array.<Object>} The list of metrics.
 */
export const getDefaultKPIsByStrategy = (strategy: Strategy, analytics: AnalyticsData = [], strategyGoalAnalyticsMetadata: StrategyGoalAnalyticsMetadataType = null) => {
  const goalType = getPrimaryGoalType(strategy.strategyGoals);
  const strategyTypeId = _.get(strategy, 'strategyType.id');
  const { revenueType } = getSingleRevTypeAndValue(strategy.revTypeConfig);

  // Define default metrics at revenue type, goal, strategyType, and global levels.
  let revenueTypeMetrics;
  let goalMetrics;
  if (revenueType) {
    revenueTypeMetrics = revenueTypeDefaultMetrics(strategyTypeId, toCamelCase(revenueType));
  }
  if (goalType) {
    goalMetrics = goalDefaultMetrics(strategyTypeId, toCamelCase(goalType));
  }
  // Segment Recency and Helios still use old endpoint
  const metricsToUse = !!_.size(analytics) && !_.isEmpty(strategyGoalAnalyticsMetadata)
    ? includeAdditionalMetrics(strategyGoalAnalyticsMetadata?.goal, _.head(analytics) as StrategyGoalAnalyticsDatum)
    : METRICS;
  const strategyMetrics = strategyDefaultMetrics(metricsToUse, strategyTypeId);
  const globalMetrics = globalDefaultMetrics(metricsToUse);

  // Pick the most specific set of metrics we can.
  const m = _.chain(revenueTypeMetrics)
    .defaultTo(goalMetrics)
    .defaultTo(strategyMetrics)
    .defaultTo(globalMetrics)
    .value();

  return m;
};

/**
 * Given a strategy, return the metrics to show in the statbox area. In
 * most cases, this is the same metrics shown in the flight table columns.
 * @param {Object} strategy The strategy for which we are fetching a set of
 * metrics.
 * @return {Object} An object with the metrics to display.
 */
export const getStatboxMetricsByStrategy = (
  strategy: Strategy,
  analytics: AnalyticsData = [],
  strategyGoalAnalyticsMetadata: StrategyGoalAnalyticsMetadataType = null,
) => getDefaultKPIsByStrategy(strategy, analytics, strategyGoalAnalyticsMetadata);

type Goal = {
  type: string,
  target: number | string,
};

type Goals = Array<Goal>;

type RatePercentageOption = {
  value: string,
  text: string,
  format: string,
};

export const getInitialRatePercentageOptionBasedOnStrategyType = (
  { strategyType, strategyGoals }: { strategyType: StrategyType, strategyGoals: Goals },
  defaultOption: RatePercentageOption,
) => {
  const type = strategyGoals && toCamelCase(strategyGoals[0].type);
  // CYOD goal metrics are currently not supported and should use the default option
  if (_.isNil(strategyType) || _.includes([GOAL_TYPES.impValuePerImp.value, GOAL_TYPES.costPerImpValue.value], type)) {
    return defaultOption;
  }
  switch (strategyType.id) {
    case STRATEGY_TYPE.helios.id:
    case STRATEGY_TYPE.heliosSegmentRecency.id:
      switch (type) {
        case GOAL_TYPES.cpa.value:
          return METRICS.ratePercentage.netCpa;
        case GOAL_TYPES.cpcv.value:
          return METRICS.ratePercentage.netCpcv;
        case GOAL_TYPES.conversionRate.value:
        case GOAL_TYPES.ctr.value:
          return _.get(METRICS.ratePercentage, type);
        case GOAL_TYPES.conversionRevenue.value:
          return METRICS.ratePercentage.conversionRevenue;
        case GOAL_TYPES.ivrMeasured.value:
          return METRICS.ratePercentage.ivrMeasured;
        case GOAL_TYPES.completionRate.value:
          return METRICS.ratePercentage.completionRate;
        default:
          return METRICS.ratePercentage.netCpc;
      }
    case STRATEGY_TYPE.customTree.id:
      return _.get(METRICS.ratePercentage, type) || METRICS.ratePercentage.ctr;
    default:
      return _.get(METRICS.ratePercentage, type, defaultOption);
  }
};

const utcDate = (date: string) => moment.utc(date, 'YYYY-MM-DD').valueOf();

export const seriesData = (data: AnalyticsData, kpi: Metric, numberOfDays: number, endDate?: Moment | null, strategy?: Strategy) => {
  const dataByDay = _.groupBy(data, (d) => utcDate(d.date));
  return _.map(generateDayRange(numberOfDays, endDate), (day) => {
    const val = _.get(dataByDay, `${day}`);
    return [day, (day && val) ? (kpi?.func(val, strategy) || 0) : null];
  });
};

/**
  * convert daily data to cumulative data for selected columns. Leaves the 'retainColumn' untouched.
 */
export function objectCumulativeSum(objs: Array<Object>, cols: Array<string>, retainColumn: string) {
  const genObject = (acc, cur) => {
    const o = _(cols)
      .map((col) => [col, acc[col] + cur[col]])
      .fromPairs()
      .thru((val) => ({ ...val, [retainColumn]: cur[retainColumn] }))
      .value();
    return o;
  };
  return accumulate((a, b) => (genObject(a, b)), objs);
}

export const buildSeriesObj = (
  configs: HighChartsConfig,
  kpi: Metric,
  name: string | null,
  data: AnalyticsData,
  seriesType: string,
  dateRange: number,
  cumulativeData: boolean,
  strategy: Strategy,
) => ({
  ...(_.omit(configs, ['colors'])),
  name,
  color: configs.color(seriesType),
  data: cumulativeData ? _.map(data, (metric) => [metric.date, (kpi.func([metric], strategy) || 0)]) : seriesData(data, kpi, dateRange, undefined, strategy),
});

export const getRateSeriesData = (data: AnalyticsData, shouldCalculateCumulative: boolean) => {
  if (shouldCalculateCumulative) {
    const cumulativeSumData = objectCumulativeSum(data, AGGREGATABLE_COLS, 'date');
    return _.map(cumulativeSumData, (csd) => ({ ...csd, date: utcDate(csd.date) }));
  }
  return data;
};

/**
 * Build a highcharts series
 *
 * @param metrics selected by the user
 * @param name object name
 * @param aggSeriesData flight/strategy metrics
 * @param cumulativeData boolean specifying if we should sum up all data
 * @param seriesType whether it's the selected or the default one
 * @returns {Array} series of computed metrics for a specific object
 */
export const buildSeries = (
  metrics: SelectedMetric,
  name: string = '',
  aggSeriesData: AnalyticsData,
  showCumulativeData: boolean,
  seriesType: string,
  selected: boolean,
  dateRange: number,
  strategy: Strategy,
) => {
  const series = [];

  // Apply cumulative/daily data option
  const rateSeriesData = getRateSeriesData(aggSeriesData, showCumulativeData);

  // Compute data based on selected metrics
  series.push(buildSeriesObj(
    chartSeriesConfigs.aggregator,
    metrics.aggregator,
    name,
    aggSeriesData,
    seriesType,
    dateRange,
    false,
    strategy,
  ));
  series.push(buildSeriesObj(
    chartSeriesConfigs.ratePercentage,
    metrics.ratePercentage,
    name,
    rateSeriesData,
    seriesType,
    dateRange,
    showCumulativeData,
    strategy,
  ));

  if (selected) {
    series.reverse();
  }
  return series;
};

export const processDailyDataPerMetrics = (
  rawAnalytics: AnalyticsData,
  metrics: { [key: string]: Metric },
  strategy: Strategy,
): { [key: string]: number } => (
  _.transform(metrics, (res, m) => {
    res[m.value] = m.func(rawAnalytics, strategy);
  })
);

// Takes an array of values to be used as object keys, and the value to set them as. Returns frozen object.
export const DefaultMetrics = ({ ...args }: {}, defaultValue: number) => {
  const newMetrics = {};
  _.each(args, (arg) => {
    // @ts-ignore
    newMetrics[arg] = defaultValue;
  });
  return Object.freeze(newMetrics);
};

// add rows containing all zeros to data tables for flights that have no data.
// the api does not return any rows for flights with no data.
export const fillZeros = (dataRows, flightExtIds, defaultKPIs) => {
  let headers;
  if (_.isEmpty(dataRows)) {
    headers = _.keys(defaultKPIs);
  } else {
    headers = _.keys(_.first(dataRows));
  }
  const extIdsWithdata = _.map(dataRows, 'externalId');
  const zeros = _.fill(Array(headers.length - 1), 0);

  return _(flightExtIds)
    .difference(extIdsWithdata)
    .map((extId) => ({ ..._.zipObject(headers, zeros), externalId: extId }))
    .concat(dataRows)
    .value();
};
