import { EnhancedStrategy, Member, Strategy, UserSettings } from 'utils/types';
import {
  DOWNLOAD_HELIOS_REPORT,
  FETCH_MEMBER,
  FETCH_ADVERTISER,
  FETCH_STRATEGY,
  FETCH_STRATEGY_TYPE,
  FILTER,
  CLEAR_RECENTLY_SAVED,
  DELETE_STRATEGY,
  DELETE_STRATEGY_COMPLETED,
  TOGGLE_MODAL,
  SET_SINGLE_STRAT_FOR_DELETION,
  FETCH_STRATEGY_FAILED,
  FETCH_STRATEGY_COMPLETED,
  DELETE_STRATEGY_FAILED,
  UPDATE_USER_SETTING,
  SAVE_STRATEGY_AND_FLIGHT_SUCCEEDED,
  SAVE_STRATEGY_AND_FLIGHT_FAILED,
  SAVE_STRATEGY_FAILED,
  FETCH_GOAL_TYPE,
  UPDATE_CLIENT_TEST_IN_LIST,
} from './constants';
import { Filter } from './components/StrategyFilterComponents';

export const getMember = (seats) => ({
  type: FETCH_MEMBER.STARTED,
  payload: { id: seats },
});

export const getMemberFailed = (message: string) => ({
  type: FETCH_MEMBER.FAILED,
  payload: message,
});

export const getMemberCompleted = (members: Array<Member>) => ({
  type: FETCH_MEMBER.COMPLETED,
  payload: members,
});

export const getAdvertiserFailed = (message: string) => ({
  type: FETCH_ADVERTISER.FAILED,
  payload: message,
});

export const getAdvertiserCompleted = (selectedAdvertiser) => ({
  type: FETCH_ADVERTISER.COMPLETED,
  payload: selectedAdvertiser,
});

export const fetchStrategyType = () => ({
  type: FETCH_STRATEGY_TYPE.STARTED,
});

export const fetchStrategyTypeCompleted = (strategyTypes) => ({
  type: FETCH_STRATEGY_TYPE.COMPLETED,
  payload: strategyTypes,
});

export const fetchStrategyTypeFailed = (message) => ({
  type: FETCH_STRATEGY_TYPE.FAILED,
  payload: message,
});

export const fetchGoalType = () => ({
  type: FETCH_GOAL_TYPE.STARTED,
});

export const fetchGoalTypeCompleted = (goalTypes: Array<any>) => ({
  type: FETCH_GOAL_TYPE.COMPLETED,
  payload: goalTypes,
});

export const fetchGoalTypeFailed = (message: string) => ({
  type: FETCH_GOAL_TYPE.FAILED,
  payload: message,
});

export const getStrategyList = (filter: Filter, limit: number, skip: number, sort?: string) => ({
  type: FETCH_STRATEGY,
  payload: {
    filter, limit, skip, sort,
  },
});

export const getStrategyFailed = (error: string) => ({
  type: FETCH_STRATEGY_FAILED,
  payload: error,
});

export const fetchStrategyCompleted = (strategyList: Array<Strategy>, strategyStatuses: Array<string>, strategyCount: number, limit: Number, skip: number) => ({
  type: FETCH_STRATEGY_COMPLETED,
  payload: {
    strategyList, strategyStatuses, strategyCount, limit, skip,
  },
});

export const updateFilterAndFetchData = (filter: Filter, limit: number, skip: number, type = FILTER.UPDATED.ANY) => ({
  type,
  payload: {
    filter,
    limit,
    skip,
  },
});

export const advertiserSearchUpdated = (filter: Filter, input: string) => ({
  type: FILTER.USER_INPUT.ADVERTISER.SEARCH_UPDATED,
  payload: { filter, input },
});

export const clearRecentlySaved = () => ({
  type: CLEAR_RECENTLY_SAVED,
});

export const toggleModal = () => ({
  type: TOGGLE_MODAL,
});

export const deleteStrategy = (strategies: Array<Strategy>) => ({
  type: DELETE_STRATEGY,
  payload: strategies,
});

export const deleteStrategyCompleted = (deleted: Array<any>) => ({
  type: DELETE_STRATEGY_COMPLETED,
  payload: deleted,
});

export const setStratForDeletion = (strategy: Array<EnhancedStrategy>) => ({
  type: SET_SINGLE_STRAT_FOR_DELETION,
  payload: strategy,
});

export const deleteStrategyFailed = () => ({
  type: DELETE_STRATEGY_FAILED,
});

export const heliosDownloadReport = ({
  strategy, flights, reportType, bundle,
}) => ({
  type: DOWNLOAD_HELIOS_REPORT.STARTED,
  payload: {
    strategy, flights, reportType, bundle,
  },
});

export const updateUserSetting = (setting: string, value: string, userSettings: UserSettings) => ({
  type: UPDATE_USER_SETTING,
  payload: { setting, value, userSettings },
});

export const saveStrategyFlightSucceeded = (id: number, name: string) => ({
  type: SAVE_STRATEGY_AND_FLIGHT_SUCCEEDED,
  payload: { id, name },
});

export const saveStrategyFlightFailed = (id: number, name: string) => ({
  type: SAVE_STRATEGY_AND_FLIGHT_FAILED,
  payload: { id, name },
});

export const saveStrategyFailed = (id: number, name: string) => ({
  type: SAVE_STRATEGY_FAILED,
  payload: { id, name },
});

export const updateClientTestInStrategyList = (strategyId: number, clientTest: boolean) => ({
  type: UPDATE_CLIENT_TEST_IN_LIST,
  payload: { strategyId, clientTest },
});
