import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field, InjectedFormProps } from 'redux-form';
import {
  Button, Form, Grid, Modal, Header, Message,
} from 'buildingBlocks';
import Toggle from 'components/Toggle';
import { Region, DSPT, Country, BusinessUnit, BusinessModel, FeeOption, FeeType, User, Permission, SeatToSaveProps } from 'utils/types';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import SeatFormFields from './SeatFormFields';
import validate from '../validate';
import { stopAlterSeat, saveSeat } from '../actions/index';
import { containerStyle } from '../style';

const FORM_NAME = 'seatForm';

type Props = {
  saveSeat: Function
  stopAlterSeat: Function
  dspList: Array<DSPT>
  countries: Array<Country>
  businessModels: Array<BusinessModel>
  businessUnits: Array<BusinessUnit>
  feeOptions: Array<FeeOption>
  feeTypes: Array<FeeType>
  regions: Array<Region>
  initialValues: {
    id: number
    enabled: boolean
  },
  seatToSave: SeatToSaveProps
  invalid: boolean
  saving: boolean
  open: boolean
  change: InjectedFormProps['change']

  errorMessage: {
    title?: string
    message?: string
  },
  user: User
  permissions: Array<Permission>
};

export class SeatForm extends Component<Props> {
  handleSubmit = (event) => {
    event.preventDefault();
    // TODO: Figure out why we need to copy accountId === prefix (this was a behavior from old UI)
    const seatObject = { ...this.props.seatToSave, accountId: this.props.seatToSave.prefix };
    this.props.saveSeat(seatObject);
  };

  stopAlterSeat = (event) => {
    event.preventDefault();
    this.props.stopAlterSeat();
  };

  render() {
    const { invalid, open } = this.props;
    const qAReadOnly = isQAGlobalReadOnly(this.props.user);
    return (
      <Modal
        open={open}
      >
        <Form
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              event.preventDefault();
            }
          }}
          loading={this.props.saving}
        >
          <Grid stackable style={{ margin: 'initial' }}>
            <Grid.Row style={{ ...containerStyle.padding, ...containerStyle.header }} columns={1}>
              <Grid.Column>
                <Header
                  content={_.get(this.props.initialValues, 'id') ? 'Edit member' : 'Create a new member'}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={containerStyle.padding} columns={1}>
              <Grid.Column>
                <SeatFormFields
                  initialValues={this.props.initialValues}
                  dspList={this.props.dspList}
                  countries={this.props.countries}
                  businessUnits={this.props.businessUnits}
                  regions={this.props.regions}
                  businessModels={this.props.businessModels}
                  feeOptions={this.props.feeOptions}
                  feeTypes={this.props.feeTypes}
                  seatToSave={this.props.seatToSave}
                  permissions={this.props.permissions}
                  change={this.props.change}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ ...containerStyle.padding, ...containerStyle.footer }} columns={2}>
              <Grid.Column>
                <Field
                  component={Toggle}
                  name="enabled"
                  label="Enabled"
                  id="enabled"
                  defaultChecked={_.get(this.props.initialValues, 'enabled', false)}
                />
              </Grid.Column>
              <Grid.Column style={{ textAlign: 'right' }}>
                <Button
                  onClick={this.stopAlterSeat}
                  content="Cancel"
                />
                <Button
                  primary
                  disabled={qAReadOnly || invalid}
                  type="submit"
                  onClick={(e) => this.handleSubmit(e)}
                  content="Save"
                />
              </Grid.Column>
            </Grid.Row>
            {
              this.props.errorMessage
              && (
              <Grid.Row centered columns={1}>
                <Grid.Column>
                  <Message
                    className="visible"
                    size="small"
                    error
                    header={this.props.errorMessage.title}
                    content={this.props.errorMessage.message}
                  />
                </Grid.Column>
              </Grid.Row>
              )
            }
          </Grid>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const mappedState = {
    dspList: state.seats.dspList,
    businessUnits: state.seats.businessUnits,
    countries: state.seats.countries,
    regions: state.seats.regions,
    initialValues: state.seats.alterSeat.seat,
    seatToSave: getFormValues(FORM_NAME)(state),
    saving: state.seats.alterSeat.saving,
    errorMessage: state.seats.alterSeat.error,
    businessModels: state.seats.businessModels,
    feeOptions: state.seats.feeOptions,
    feeTypes: state.seats.feeTypes,
    user: state.login.session.data.user,
    permissions: state.seats.permissions,
  };
  return mappedState;
}

const mapDispatchToProps = {
  saveSeat,
  stopAlterSeat,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate,
  // @ts-ignore - redux
})(SeatForm));
