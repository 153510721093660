import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'qs';
import {
  Grid, Header, Segment, Table, ObjectMultiDropdown, ObjectDropdownSearch, Label, Dropdown,
} from 'buildingBlocks';
import DataLoading from 'components/DataLoading';
import DateRangePicker from 'components/DateRangePicker';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import PaginationControl from 'components/PaginationControl';
import { ALGORITHM_TYPE, DSP, EXCLUDE_STRATEGY_TYPES } from 'constantsBase';
import { TIME_ZONE_OPTIONS } from 'containers/Jobs/constants';
import { TimeZoneValue } from 'containers/Jobs/types';
import { dateTimeFormatter } from 'utils/dateTime';
import { Permission } from 'utils/featureFlags';
import { formatNumber, camelize } from 'utils/formattingUtils';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import withRouter from 'utils/withRouter';
import {
  getStrategyFlightRuns, getMembers, strategySearchUpdated, flightSearchUpdated, algorithmSearchUpdated,
  fetchStrategyType, fetchAlgorithmType, updateFilterAndFetchData,
} from './actions';
import {
  STRATEGY_FLIGHT_RUNS_COLUMNS, STRATEGY_FLIGHT_RUNS_ELEMENTS_PER_PAGE_OPTIONS, STRATEGY_FLIGHT_RUNS_FILTER,
  STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE,
} from './constants';
import { DEFAULT_FILTER } from './reducer';
import { strategyFlightRunsTable, tableHeader, cellPadding } from './style';
import { StrategyFlightRunsProps, StrategyFlightRunsState } from './types';

const REQUIRED_PERMISSIONS = [Permission.strategyFlightRuns];
const PAGE_NAME = 'Flight Runs';

const FilterDropdown = <T extends {}>({ filterType, ...passthroughProps }: FilterDropdownProps<T>) => (
  <ObjectMultiDropdown
    {...passthroughProps}
    fluid
    selection
    scrolling
    // @ts-ignore ObjectMultiDropdown
    id={filterType.name}
    name={filterType.name}
    placeholder={filterType.placeholder}
    disabled={passthroughProps.loading || !!passthroughProps.error}
    noResultsMessage={passthroughProps.options.length > 0
      ? 'No remaining options.'
      : `Search ${filterType.placeholder}s.`}
  />
);

type FilterDropdownProps<T> = {
  filterType: {
    placeholder: string,
    name: string,
  },
  loading?: boolean,
  error?: string,
  options: Array<T>,
  value: Array<T>,
  onChange: Function,
  keyFn?: (obj: T) => string,
  search?: ObjectDropdownSearch,
};

const formatAlgoTypeName = (algoType) => camelize(_.replace(algoType.name, /_/g, ' '));

class StrategyFlightRuns extends Component<StrategyFlightRunsProps, StrategyFlightRunsState> {
  static getTriggerType(sfr) {
    const triggerType = sfr.triggerType ? 'User Initiated' : 'Programmatic';
    if (sfr.result && sfr.result.dag_run_url) {
      return (
        <a
          href={sfr.result.dag_run_url}
          target="_blank"
          rel="noopener noreferrer"
        >{triggerType}
        </a>
      );
    }
    return triggerType;
  }

  constructor(props: StrategyFlightRunsProps) {
    super(props);
    this.state = {
      startingPage: 1,
      limit: STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE,
      skip: 0,
      selectedTimezone: TimeZoneValue.utc,
    };
    this.props.getMembers();
    this.browserUrlUpdate = this.browserUrlUpdate.bind(this);
    this.onDataRangeChange = this.onDataRangeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const router = this.props.router;
    const { location } = router;
    const query = qs.parse(location.search.replace(/^\?/, ''));
    const parsedLimit = (_.toNumber(query.limit) > 0 && parseInt(query.limit as string, 10))
      || STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE;
    const parsedSkip = (_.toNumber(query.skip) >= 0 && parseInt(query.skip as string, 10)) || 0;
    this.updateState(parsedLimit, parsedSkip);

    this.props.fetchStrategyType();
    this.props.fetchAlgorithmType();
    this.initFromQueryString(query);
  }

  onDataRangeChange({ start, end }) {
    const limit = end - start;
    const skip = start;
    this.updateState(limit, skip);
    this.browserUrlUpdate(this.props.filter, limit, skip);
    this.props.getStrategyFlightRuns(this.props.filter, limit, skip);
  }

  onFilterUpdate(filter) {
    this.updateState(this.state.limit, 0);
    this.props.updateFilterAndFetchData(filter, this.state.limit, 0);
    this.browserUrlUpdate(filter, this.state.limit, 0);
  }

  onDateRangeSubmit(startDate, endDate) {
    const filter = {
      ...this.props.filter,
      startDate: dateTimeFormatter.isoDateTime(startDate),
      endDate: dateTimeFormatter.isoDateTime(endDate),
    };
    this.props.updateFilterAndFetchData(filter, this.state.limit, 0);
    this.browserUrlUpdate(filter, this.state.limit, 0);
  }

  updateState(limit, skip) {
    this.setState({ limit, skip, startingPage: (skip / limit) + 1 });
  }

  browserUrlUpdate(filter, limit, skip) {
    const router = this.props.router;
    const { location, navigate } = router;
    const newFilter = { ...filter, limit, skip };
    navigate({
      pathname: location.pathname,
      search: `?${qs.stringify(newFilter)}`,
    });
  }

  initFromQueryString(query) {
    const getValue = (id) => _.chain(query)
      .get(id, [])
      // @ts-ignore field within query should be an array
      .map((val) => (_.isNaN(_.toNumber(val)) ? val : _.toNumber(val)))
      .value();

    const filterFields = [
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.STRATEGIES,
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.FLIGHTS,
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.ALGORITHMS,
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.MEMBERS,
      STRATEGY_FLIGHT_RUNS_FILTER.STATUS,
      STRATEGY_FLIGHT_RUNS_FILTER.STRATEGY_TYPE,
      STRATEGY_FLIGHT_RUNS_FILTER.ALGORITHM_TYPE,
      STRATEGY_FLIGHT_RUNS_FILTER.TRIGGER_TYPE,
    ];

    const filter = _(filterFields)
      .map(({ id }) => ([id, getValue(id)]))
      .fromPairs()
      .defaults(DEFAULT_FILTER)
      .value();

    filter.startDate = _.join(query.startDate, '') || DEFAULT_FILTER.startDate;
    filter.endDate = _.join(query.endDate, '') || DEFAULT_FILTER.endDate;
    const limit = query.limit || this.state.limit;
    const skip = query.skip || this.state.skip;

    this.props.updateFilterAndFetchData(filter, limit, skip);
  }

  handleTimezoneChange = (_e, data) => {
    this.setState({ selectedTimezone: data.value });
  };

  renderFilter() {
    const memberOptions = getOptionsWithDspIcon(this.props.members);
    const stratTypeOptions = getOptionsWithDspIcon(_.sortBy(_.reject(this.props.strategyTypes, ({ id }) => _.includes(EXCLUDE_STRATEGY_TYPES, id)), 'displayName'));
    return (
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <DateRangePicker
                onDateRangeSubmit={(startDate, endDate) => this.onDateRangeSubmit(startDate, endDate)}
                initialStartDate={moment(this.props.filter.startDate)}
                initialEndDate={moment(this.props.filter.endDate)}
                enableFutureDateSelection
                lookbackDays={65}
                timeZoneEnabled
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <div className="ui form field">
                <label htmlFor="timeZone">Time Zone</label>
                <Dropdown
                  name="timeZone"
                  placeholder=""
                  options={TIME_ZONE_OPTIONS}
                  fluid
                  selection
                  defaultValue={this.state.selectedTimezone}
                  onChange={this.handleTimezoneChange}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <FilterDropdown
                onChange={(strategies) => this.onFilterUpdate({
                  ...this.props.filter,
                  strategy: _.map(strategies, 'id'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.STRATEGIES}
                options={this.props.strategies}
                value={this.props.selected.strategy}
                keyFn={(strategy) => `${strategy.id}`}
                search={{
                  searchType: 'api',
                  onSearchChange: (_e, { searchQuery: search }) => {
                    this.props.strategySearchUpdated(this.props.filter, search);
                  },
                  debounce: { timer: 250 },
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <FilterDropdown
                onChange={(algorithms) => this.onFilterUpdate({
                  ...this.props.filter,
                  algorithm: _.map(algorithms, 'id'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.ALGORITHMS}
                options={this.props.algorithms}
                value={this.props.selected.algorithm}
                keyFn={(algorithm) => `${algorithm.id}`}
                search={{
                  searchType: 'api',
                  onSearchChange: (_e, { searchQuery: search }) => {
                    this.props.algorithmSearchUpdated(this.props.filter, search);
                  },
                  debounce: { timer: 250 },
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <FilterDropdown
                onChange={(flights) => this.onFilterUpdate({
                  ...this.props.filter,
                  flight: _.map(flights, 'id'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.FLIGHTS}
                options={this.props.flights}
                value={this.props.selected.flight}
                keyFn={(flight) => `${flight.id}`}
                search={{
                  searchType: 'api',
                  onSearchChange: (_e, { searchQuery: search }) => {
                    this.props.flightSearchUpdated(this.props.filter, search);
                  },
                  debounce: { timer: 250 },
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <FilterDropdown
                onChange={(members) => this.onFilterUpdate({
                  ...this.props.filter,
                  member: _.map(members, 'id'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.MEMBERS}
                options={memberOptions}
                value={this.props.selected.member}
                keyFn={(mem) => (
                  `${DSP.getById(mem.dsp as number).code} `
                  + `- ${_.get(mem, 'displayName', _.get(mem, 'name'))}`
                )}
                search={{
                  searchType: 'local',
                  onSearchChange: searchByNameOrExtId(this.props.selected.member),
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <FilterDropdown
                onChange={(status) => this.onFilterUpdate({
                  ...this.props.filter,
                  status: _.map(status, 'value'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.STATUS}
                options={STRATEGY_FLIGHT_RUNS_FILTER.STATUS.options}
                value={this.props.selected.status}
                keyFn={(status) => status.text}
              />
            </Grid.Column>
            <Grid.Column>
              <FilterDropdown
                onChange={(triggerTypes) => this.onFilterUpdate({
                  ...this.props.filter,
                  triggerType: _.map(triggerTypes, 'value'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.TRIGGER_TYPE}
                options={STRATEGY_FLIGHT_RUNS_FILTER.TRIGGER_TYPE.options}
                value={this.props.selected.triggerType}
                keyFn={(triggerType) => triggerType.text}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FilterDropdown
                onChange={(selectedTypes) => this.onFilterUpdate({
                  ...this.props.filter,
                  strategyType: _.map(selectedTypes, 'id'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.STRATEGY_TYPE}
                options={stratTypeOptions}
                value={this.props.selected.strategyType}
                keyFn={(st) => `${DSP.getById(st.dsp as number).code} - ${st.displayName}`}
              />
            </Grid.Column>
            <Grid.Column>
              <FilterDropdown
                onChange={(selectedTypes) => this.onFilterUpdate({
                  ...this.props.filter,
                  algorithmType: _.map(selectedTypes, 'id'),
                })}
                filterType={STRATEGY_FLIGHT_RUNS_FILTER.ALGORITHM_TYPE}
                options={_.sortBy(this.props.algorithmTypes, (at) => at.name)}
                value={this.props.selected.algorithmType}
                keyFn={(algorithmType) => formatAlgoTypeName(algorithmType)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderStrategyFlightRunsTableBody() {
    const toLink = (sfr) => `dashboard?strategy=${sfr.strategy}&flight=${sfr.flight}&run=${sfr.id}`;
    const typeLabel = (run) => run.strategyType && run.strategyType.displayName;
    const algoTypeLabel = (run) => run.algorithmType && formatAlgoTypeName(run.algorithmType);
    const memberLabel = (member, algorithmTypeId) => {
      if (algorithmTypeId === ALGORITHM_TYPE.crossPlatformOptimization.id) {
        return 'Multiple';
      }
      return `${member.displayName} (${member.externalId})`;
    };
    return (
      <Table.Body>
        {_.map(this.props.strategyFlightRuns, (strategyFlightRun) => (
          <Table.Row key={strategyFlightRun.id}>
            <Table.Cell title={strategyFlightRun.id}>
              <Link to={toLink(strategyFlightRun)}>{strategyFlightRun.id}</Link>
            </Table.Cell>
            <Table.Cell title={typeLabel(strategyFlightRun)} style={cellPadding}>
              <Label basic>
                {typeLabel(strategyFlightRun)}
              </Label>
            </Table.Cell>
            <Table.Cell title={algoTypeLabel(strategyFlightRun)} style={cellPadding}>
              {strategyFlightRun.algorithm && (
                <Label basic>
                  {algoTypeLabel(strategyFlightRun)}
                </Label>
              )}
            </Table.Cell>
            <Table.Cell title={strategyFlightRun.strategy}>
              <Link
                to={`/strategies/${strategyFlightRun.strategy}`}
                target="_blank"
              >{strategyFlightRun.strategy}
              </Link>
            </Table.Cell>
            <Table.Cell title={strategyFlightRun.algorithm}>{strategyFlightRun.algorithm}</Table.Cell>
            <Table.Cell title={strategyFlightRun.flight}>{strategyFlightRun.flight}</Table.Cell>
            <Table.Cell title={strategyFlightRun.extFlightId}>{strategyFlightRun.extFlightId}</Table.Cell>
            <Table.Cell title={memberLabel(strategyFlightRun.member, strategyFlightRun.algorithmType.id)}>
              {memberLabel(strategyFlightRun.member, strategyFlightRun.algorithmType.id)}
            </Table.Cell>
            <Table.Cell title={strategyFlightRun.status}>{strategyFlightRun.status}</Table.Cell>
            <Table.Cell title={strategyFlightRun.triggerType ? 'User Initiated' : 'Programmatic'}>
              {StrategyFlightRuns.getTriggerType(strategyFlightRun)}
            </Table.Cell>
            <Table.Cell title={dateTimeFormatter.timeZoneBasedDateTime(this.state.selectedTimezone, strategyFlightRun.updatedAt, this.props.userTimeZone)}>
              {dateTimeFormatter.timeZoneBasedDateTime(this.state.selectedTimezone, strategyFlightRun.updatedAt, this.props.userTimeZone)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  renderStrategyFlightRunsTable() {
    const totalElements = this.props.strategyFlightRunsCount;
    const startIndex = ((this.state.startingPage - 1) * this.state.limit) + 1;
    const endIndex = (startIndex + this.props.strategyFlightRuns.length) - 1;
    const headerStyle = { fontWeight: 'normal' };
    const emptyHeader = <Header style={headerStyle} as="h5"> No matching runs - try changing your filters. </Header>;
    const fullHeader = (
      <Header style={headerStyle} as="h5">
        <b>{formatNumber(startIndex)} - {formatNumber(endIndex)}</b> of <b>{formatNumber(totalElements)}</b>
      </Header>
    );

    return (
      <Grid.Column>
        {totalElements ? fullHeader : emptyHeader}
        <Table fixed style={strategyFlightRunsTable}>
          <Table.Header>
            <Table.Row>
              {_.map(STRATEGY_FLIGHT_RUNS_COLUMNS, (column) => (
                <Table.HeaderCell
                  title={column.text}
                  key={column.value}
                  style={tableHeader}
                >{column.text}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          {this.renderStrategyFlightRunsTableBody()}
        </Table>
      </Grid.Column>
    );
  }

  renderPagination() {
    return (
      <Grid.Column>
        <PaginationControl
          numberOfElements={this.props.strategyFlightRunsCount}
          onDataRangeChange={(obj) => this.onDataRangeChange(obj)}
          startingElementsPerPage={this.state.limit}
          elementsPerPageOptions={STRATEGY_FLIGHT_RUNS_ELEMENTS_PER_PAGE_OPTIONS}
          startingPage={this.state.startingPage}
        />
      </Grid.Column>
    );
  }

  renderStrategyFlightRunsSection() {
    return (
      <Grid>
        <Grid.Row>
          {this.renderStrategyFlightRunsTable()}
        </Grid.Row>
        <Grid.Row>
          {this.renderPagination()}
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    return (
      <DataLoading loading={this.props.loading} pageName={PAGE_NAME}>
        <div>
          {this.renderFilter()}
          {this.renderStrategyFlightRunsSection()}
        </div>
      </DataLoading>
    );
  }
}

const mapStateToProps = (state) => ({
  strategyFlightRuns: state.strategyFlightRuns.strategyFlightRuns,
  strategyFlightRunsCount: state.strategyFlightRuns.strategyFlightRunsCount,
  strategyTypes: state.strategyFlightRuns.strategyTypes,
  strategies: state.strategyFlightRuns.strategies,
  flights: state.strategyFlightRuns.flights,
  algorithms: state.strategyFlightRuns.algorithms,
  members: state.strategyFlightRuns.members,
  algorithmTypes: state.strategyFlightRuns.algorithmTypes,
  filter: state.strategyFlightRuns.filter,
  selected: state.strategyFlightRuns.selected,
  loading: state.strategyFlightRuns.loading,
  userTimeZone: state.login.settings.config.timeZone,
});

const mapDispatchToProps = (dispatch) => ({
  getStrategyFlightRuns: bindActionCreators(getStrategyFlightRuns, dispatch),
  getMembers: bindActionCreators(getMembers, dispatch),
  fetchStrategyType: bindActionCreators(fetchStrategyType, dispatch),
  fetchAlgorithmType: bindActionCreators(fetchAlgorithmType, dispatch),
  strategySearchUpdated: bindActionCreators(strategySearchUpdated, dispatch),
  flightSearchUpdated: bindActionCreators(flightSearchUpdated, dispatch),
  algorithmSearchUpdated: bindActionCreators(algorithmSearchUpdated, dispatch),
  updateFilterAndFetchData: bindActionCreators(updateFilterAndFetchData, dispatch),
});

const PermissionStrategyFlightRuns = (props) => (
  <PermissionPageTemplate
    title={PAGE_NAME}
    name={PAGE_NAME}
    permissions={REQUIRED_PERMISSIONS}
  >
    <StrategyFlightRuns {...props} />
  </PermissionPageTemplate>
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PermissionStrategyFlightRuns));
