import _ from 'lodash';
import fp from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { ObjectDropdown, WppActionButton, WppGrid } from 'buildingBlocks';
import { DSP } from 'constantsBase';
import { getOptionsWithDspIcon, getStrategyOptions } from 'components/OptionWithIcon/OptionWithIcon';
import WppSortDropdown from 'components/WppSortDropdown';
import { COPILOT_COLORS } from 'globalStyles';
import { GlobalState } from 'reducers';
import { getStrategyMembers } from 'utils/featureFlags';
import { coerceBoolStringToBool, isQAGlobalReadOnly } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { User, UserSettings, AdvertiserWithPopulatedMember } from 'utils/types';
import { isAdmin } from 'containers/User/utils';
import { Filter, FilterDropdown, PrettyAdvertiserName } from './StrategyFilterComponents';
import { WPP_SORT_OPTIONS, FILTER, DEFAULT_FILTER, sortBy, STRATEGIES_LIST_VIEW_OPTIONS, StrategyListView, StrategyListViewOptionType } from '../constants';
import '../style.scss';
import { getMember, updateFilterAndFetchData, advertiserSearchUpdated, fetchStrategyType, fetchGoalType, updateUserSetting } from '../actions';
import style, { sortDropdownContainer } from '../style';

const errorColor = COPILOT_COLORS.NEW_DESIGN_SYSTEM.REDS.R500_WATERMELON;

type StrategyFilterProps = {
  user: User
  browserUrlUpdate: Function
  limit: number
  skip: number
  location: {
    search: string
    pathname: string
  }
};

const StrategyFilter = (props: StrategyFilterProps) => {
  const {
    user,
    location,
    limit,
    skip,
    browserUrlUpdate,
  } = props;
  const {
    filter,
    selected,
    advertiser,
    strategyTypes,
    goalTypes: goalTypeOptions,
    advertiserSearch,
    member,
    loadingAdvertiser,
    loadingGoalType,
    loadingMember,
    loadingStrategyType,
    memberFetchError,
    advertiserFetchError,
    goalTypeFetchError,
  } = useSelector<GlobalState>((state) => state.strategiesList) as any;
  const userId = _.toString(user.id);
  const userSettings = useSelector<GlobalState>((state) => state.login.settings) as UserSettings;
  const userViewMode = _.get(userSettings, 'config.strategyListView', StrategyListView.myUser) as StrategyListView;
  const [strategyListView, setStrategyListView] = useState<StrategyListView>(userViewMode);

  const loading = loadingAdvertiser || loadingMember || loadingStrategyType || loadingGoalType;
  const firstRenderRef = useRef(true);
  const isAdminUser = isAdmin(user);
  const isQAGlobal = isQAGlobalReadOnly(user);
  const isAdminOrQAGlobal = isAdminUser || isQAGlobal;

  const dispatch = useDispatch();

  const onFilterUpdate = (fil: Filter, type?: string) => {
    browserUrlUpdate(fil, limit, 0);
    dispatch(updateFilterAndFetchData(fil, limit, 0, type));
  };

  const initFromQueryString = () => {
    const view = strategyListView === StrategyListView.allUsers ? userViewMode : userId;
    const defaultFilter = { ...DEFAULT_FILTER };
    const isDefault = _.isEqual({ ...filter }, defaultFilter);
    const query = qs.parse(location.search.replace(/^\?/, ''));
    const value = (id) => (
      _.chain(query)
        .get(id, [])
        .map((el) => (
          ((_.isNaN(_.toNumber(el))
            ? el
            : _.toNumber(el)))
        ))
        .value()
    );

    const filters = isDefault
      ? _(FILTER.USER_INPUT)
        .values()
        .map('ID')
        .map((id) => ([id, value(id)]))
        .fromPairs()
        .defaults(defaultFilter)
        .value()
      : { ...filter };

    filters.sort = _.join(filters.sort, '') || defaultFilter.sort;
    filters.order = _.join(filters.order, '') || defaultFilter.order;
    filters.createdBy = _.join(filters.createdBy, '') || defaultFilter.createdBy;
    filters.updatedBy = _.join(filters.updatedBy, '') || defaultFilter.updatedBy;
    filters.starred = query.starred ? coerceBoolStringToBool(query.starred) : defaultFilter.starred;

    if (!filters.createdBy) {
      filters.createdBy = view;
    }
    if (!filters.updatedBy) {
      filters.updatedBy = view;
    }
    // if starred is true update the filters accordingly
    if (filters.starred) {
      setStrategyListView(StrategyListView.starred);
      filters.createdBy = StrategyListView.allUsers;
      filters.updatedBy = StrategyListView.allUsers;
    }
    dispatch(updateFilterAndFetchData(filters, limit, skip));
  };

  useMount(() => {
    const memberIds = getStrategyMembers(user);
    dispatch(fetchStrategyType());
    dispatch(fetchGoalType());
    dispatch(getMember(memberIds));
    initFromQueryString();
  });

  useEffect(() => {
    // only runs after the first rendering
    if (!loading && !firstRenderRef.current) {
      // remove unauthorized filter stuff
      const newFilter = {
        ...selected,
        member: _.map(selected.member, 'id'),
        advertiser: _.map(selected.advertiser, 'id'),
        strategyType: _.map(selected.strategyType, 'id'),
        goalType: _.map(selected.goalType, 'id'),
        starred: strategyListView === StrategyListView.starred,
      } as Filter;
      if (!_.isEqual(filter, newFilter)) {
        onFilterUpdate(newFilter);
      }
    }
    // set the ref to false after first render
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const hasSelectedFilters = () => (
    (
      filter.member.length
      || filter.advertiser.length
      || filter.strategyType.length
      || filter.goalType.length
    ) > 0
  );

  const isAdminAndSelectedFilters = !isAdminOrQAGlobal && hasSelectedFilters();

  const memberOptions = getOptionsWithDspIcon(member);
  const stratTypeOptions = getOptionsWithDspIcon(_.sortBy(strategyTypes, 'displayName'));
  // data in some DBs is inconsistent and missing members so we'll filter them out so the UI doesn't have issues.
  const advertiserList = fp.flow(
    fp.filter((adv) => _.get(adv, 'member')),
    fp.map((adv: any) => ({ ...adv, content: <PrettyAdvertiserName advertiser={adv as AdvertiserWithPopulatedMember} /> })),
  )(advertiser);
  const selectedAdvertiserList = _.map(
    selected.advertiser,
    (adv) => ({ ...adv, content: <PrettyAdvertiserName advertiser={adv as AdvertiserWithPopulatedMember} /> }),
  );

  const strategyOptions = getStrategyOptions(STRATEGIES_LIST_VIEW_OPTIONS);
  const handleStrategiesListViewChange = ({ value: stratListView }: StrategyListViewOptionType) => {
    const newFilter = { ...filter };
    setStrategyListView(stratListView);
    if (_.isEqual(stratListView, StrategyListView.myUser)) {
      newFilter.createdBy = userId;
      newFilter.updatedBy = userId;
      newFilter.starred = false;
    }
    if (_.isEqual(stratListView, StrategyListView.allUsers)) {
      newFilter.createdBy = StrategyListView.allUsers;
      newFilter.updatedBy = StrategyListView.allUsers;
      newFilter.starred = false;
    }
    if (_.isEqual(stratListView, StrategyListView.starred)) {
      newFilter.createdBy = StrategyListView.allUsers;
      newFilter.updatedBy = StrategyListView.allUsers;
      newFilter.starred = true;
    }
    if (!_.isEqual(stratListView, StrategyListView.starred)) {
      dispatch(updateUserSetting('strategyListView', stratListView, userSettings));
    }
    onFilterUpdate(newFilter);
  };

  return (
    <WppGrid
      container
      fullWidth
      all={24}
      id="strategy-filter-row"
      style={style.filterContainer}
    >
      <WppGrid item all={4}>
        <ObjectDropdown
          fluid
          selection
          onChange={handleStrategiesListViewChange}
          options={strategyOptions}
          value={_.find(strategyOptions, ['value', strategyListView])}
          keyFn={(slv) => slv.text}
        />
      </WppGrid>
      <WppGrid item all={4}>
        <FilterDropdown
          onChange={(members) => {
            const mem = _.map(members, 'id');
            onFilterUpdate({ ...filter, member: mem });
          }}
          filterType={FILTER.USER_INPUT.MEMBER}
          options={memberOptions}
          loading={loadingMember}
          error={memberFetchError}
          value={selected.member}
          renderLabel={(label) => ({ content: label.content })}
          keyFn={(mem) => `${DSP.getById(mem.dsp).code} - ${_.get(mem, 'displayName', _.get(mem, 'name'))}`}
          search={{ searchType: 'local', onSearchChange: searchByNameOrExtId(selected.member) }}
        />
        <span style={{ color: errorColor }}>{memberFetchError}</span>
      </WppGrid>
      <WppGrid item all={4}>
        <FilterDropdown
          onChange={(selectedTypes) => {
            const strategyType = _.map(selectedTypes, 'id');
            onFilterUpdate({ ...filter, strategyType });
          }}
          filterType={FILTER.USER_INPUT.STRATEGY}
          options={stratTypeOptions}
          value={selected.strategyType}
          keyFn={(st) => `${DSP.getById(st.dsp).code} - ${st.displayName}`}
          search={{ searchType: 'local' }}
        />
        <span style={{ color: errorColor }}>{advertiserFetchError}</span>
      </WppGrid>
      <WppGrid item all={4}>
        <FilterDropdown
          onChange={(advertisers) => {
            const adv = _.map(advertisers, 'id');
            onFilterUpdate({ ...filter, advertiser: adv });
          }}
          filterType={FILTER.USER_INPUT.ADVERTISER}
          options={_.filter(advertiserList, (v) => v.member !== null)}
          loading={loadingAdvertiser}
          error={advertiserFetchError}
          value={selectedAdvertiserList}
          renderLabel={(label) => ({ content: label.content })}
          keyFn={(adv) => `[${adv.member.displayName}] - ${adv.name} (${adv.externalId})`}
          search={{
            searchType: 'api',
            onSearchChange: (_e, input) => {
              if (advertiserSearch !== input.searchQuery) {
                dispatch(advertiserSearchUpdated(filter, input.searchQuery));
              }
            },
            debounce: { timer: 250 },
          }}
        />
        <span style={{ color: errorColor }}>{advertiserFetchError}</span>
      </WppGrid>
      <WppGrid item all={8} style={{ ...style.filerComponent, justifyContent: isAdminAndSelectedFilters ? 'space-between' : 'flex-end' }}>
        {isAdminAndSelectedFilters && (
          <WppActionButton
            id="clear-filter-btn"
            onClick={() => onFilterUpdate({
              ...DEFAULT_FILTER,
              createdBy: filter.createdBy,
              updatedBy: filter.updatedBy,
              sort: filter.sort,
              order: filter.order,
            })}
          >
            Clear Filter
          </WppActionButton>
        )}
        <div style={{ ...sortDropdownContainer, display: 'flex' }}>
          <div id="sort-last-modified">
            <WppSortDropdown
              name="sortDropDown"
              text={sortBy}
              options={WPP_SORT_OPTIONS}
              onChange={(data) => {
                const sorted = { sort: data.value, order: data.order };
                onFilterUpdate({ ...filter, ...sorted }, FILTER.UPDATED.SORT);
              }}
              value={selected.sort}
              order={selected.order}
            />
          </div>
        </div>
      </WppGrid>
      <WppGrid item all={7} className="goalTypeFilter">
        {
          (isAdminOrQAGlobal
            && (
              <div className="goalTypeDropdown">
                <FilterDropdown
                  onChange={(goalTypes) => {
                    const goalType = _.map(goalTypes, 'id');
                    onFilterUpdate({ ...filter, goalType });
                  }}
                  filterType={FILTER.USER_INPUT.GOAL_TYPE}
                  options={goalTypeOptions}
                  loading={loadingGoalType}
                  value={selected.goalType}
                  keyFn={(st) => st.displayName}
                  search={{ searchType: 'local' }}
                />
                <span style={{ color: errorColor }}>{goalTypeFetchError}</span>
              </div>
            )
          )
        }
        {isAdminOrQAGlobal && hasSelectedFilters() && (
          <WppActionButton
            id="clear-filter-btn"
            onClick={() => onFilterUpdate({
              ...DEFAULT_FILTER,
              createdBy: filter.createdBy,
              updatedBy: filter.updatedBy,
              sort: filter.sort,
              order: filter.order,
            })}
          >
            Clear Filter
          </WppActionButton>
        )}
      </WppGrid>
    </WppGrid>
  );
};

export default StrategyFilter;
