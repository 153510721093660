import React from 'react';
import _ from 'lodash';
import { Table, Button, Icon, Popup, Message } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { convertDatabaseDateToLongDate } from 'utils/formattingUtils';
import { assertUnreachable, FetchState } from 'utils/types';
import { isValidDSP } from 'utils/functionHelpers';
import { memberButtons } from '../style';

// TODO combine with utils/types::Member once we have a population strategy
export type Seat = {
  id: number,
  accountId: number,
  externalId: string,
  prefix: number,
  displayName: string,
  name: string,
  dsp: {
    displayName: string,
    code: string,
    id: number,
    createdAt: string,
    updatedAt: string,
  },
  country: {
    name: string,
    isocode: string,
    isonumber: number,
    region: string,
    id: number,
    createdAt: string,
    updatedAt: string,
  },
  businessModel: {
    name: string,
    id: number,
    createdAt: string,
    updatedAt: string,
  },
  businessUnit: {
    name: string,
    id: number,
    createdAt: string,
    updatedAt: string,
  },
  feeOption: {
    name: string,
    id: number,
    createdAt: string,
    updatedAt: string,
  },
  feeType: {
    name: string,
    id: number,
    createdAt: string,
    updatedAt: string,
  },
  feeValue: number,
  region: {
    name: string,
    id: number,
    createdAt: string,
    updatedAt: string,
  },
  apiUser: string,
  apiPassword: string,
  enabled: boolean,
  createdAt: string,
  updatedAt: string,
};

export type SeatStatusStates =
  | { kind: FetchState.initial }
  | { kind: FetchState.loading }
  | { kind: FetchState.success }
  | { kind: FetchState.error, errors: Array<string> };

type Props = {
  seat: Seat,
  editSeat: Function,
  checkSeatCredentials: Function,
  loading?: boolean,
  negative?: boolean,
  positive?: boolean,
  renderStatusInfo?: () => JSX.Element,
};

type SeatDetailsProps = {
  seat: Seat,
  editSeat: Function,
  checkSeatCredentials: Function,
  seatConnectionStatus: SeatStatusStates,
};

export const SeatDetailsBase = ({
  seat, checkSeatCredentials, editSeat, loading, negative, positive, renderStatusInfo,
}: Props) => (
  <Table.Row
    negative={negative}
    positive={positive}
  >
    <Table.Cell title={seat.id}>{seat.id}</Table.Cell>
    <Table.Cell title={seat.externalId}>{seat.externalId}</Table.Cell>
    <Table.Cell title={seat.prefix}>{seat.prefix}</Table.Cell>
    <Table.Cell title={seat.name}>{seat.name}</Table.Cell>
    <Table.Cell title={seat.dsp.id}><DspIcon dspId={seat.dsp.id} /></Table.Cell>
    <Table.Cell title={seat.displayName}>{seat.displayName}</Table.Cell>
    <Table.Cell title={seat.country.name}>{seat.country.name}</Table.Cell>
    <Table.Cell title={seat.businessUnit.name}>{seat.businessUnit.name}</Table.Cell>
    <Table.Cell title={seat.region.name}>{seat.region.name}</Table.Cell>
    <Table.Cell title={seat.apiUser}>
      {_.isFunction(renderStatusInfo) && renderStatusInfo()}
      {seat.apiUser}
    </Table.Cell>
    <Table.Cell><Icon name={seat.enabled ? 'checkmark box' : 'square outline'} size="large" /></Table.Cell>
    <Table.Cell title={convertDatabaseDateToLongDate(seat.createdAt)}>
      {convertDatabaseDateToLongDate(seat.createdAt)}
    </Table.Cell>
    <Table.Cell title={convertDatabaseDateToLongDate(seat.updatedAt)}>
      {convertDatabaseDateToLongDate(seat.updatedAt)}
    </Table.Cell>
    {/* padding 9px */}
    <Table.Cell style={memberButtons}><Button onClick={() => editSeat(seat)} content="Edit" /></Table.Cell>
    <Table.Cell style={memberButtons}>
      <Button
        onClick={() => checkSeatCredentials(seat)}
        content="Test"
        disabled={!isValidDSP(seat.dsp.id)}
        loading={loading}
      />
    </Table.Cell>
  </Table.Row>
);

const ConnectionStatusInfo = ({ errors }: { errors: Array<string> }) => (
  <span>
    <Popup
      hoverable
      wide="very"
      trigger={<span><Icon name="info circle" /></span>}
      content={_.map(errors, (e) => <Message color="red">Error: {`${JSON.stringify(e)}`}</Message>)}
    />
  </span>
);

export const SeatDetails = ({ seatConnectionStatus, ...props }: SeatDetailsProps) => {
  switch (seatConnectionStatus.kind) {
    case FetchState.initial:
      return <SeatDetailsBase {...props} />;

    case FetchState.loading:
      return <SeatDetailsBase loading {...props} />;

    case FetchState.success:
      return <SeatDetailsBase positive {...props} />;

    case FetchState.error:
      return (
        <SeatDetailsBase
          negative
          renderStatusInfo={() => <ConnectionStatusInfo errors={seatConnectionStatus.errors} />}
          {...props}
        />
      );
    default:
      return assertUnreachable(seatConnectionStatus);
  }
};

export default SeatDetails;
